<template>
  <div class="c-curtain-feedback-box-item">
    <div class="c-curtain-feedback-box-item__wrapper">
      <div class="c-curtain-feedback-box-item__left">
        <div v-if="imgUrl" class="c-curtain-feedback-box-item__img-wrapper">
          <img :src="imgUrl.url" :alt="item.name">
        </div>
        <TheHeading level="h4" class="c-curtain-feedback-box-item__title">
          {{ item.name }}
        </TheHeading>
      </div>
      <div class="c-curtain-feedback-box-item__right">
        <TheButton
          class="c-curtain-feedback-box-item__btn"
          type="primary"
          icon="arrow-right"
          iconPos="right"
          @click="goToNextActivity"
        >
          {{ $t('CURTAIN_FEEDBACK_NEXT') }}
        </TheButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { useAssets, utils } from '@/core'
import useCurtain from '@/composables/useCurtain'
import TheHeading from '@/components/TheHeading'
import TheButton from '@/components/TheButton'

export default {
  components: {
    TheButton,
    TheHeading,
  },

  props: {
    item: Object,
  },

  setup(props) {
    const { fetchAsset, getAssetById } = useAssets()
    const { addCloudinaryUrlParams } = utils
    const { closeCurtain } = useCurtain()
    const router = useRouter()
    const route = useRoute()
    const imgUrl = ref('')
    const goToNextActivity = () => {
      router.push(`/activity/${route.params.arena}/${props.item.slug}`)
      closeCurtain()
    }
    onMounted(async () => {
      if (props.item?.cover) {
        await fetchAsset(props.item.cover)
        imgUrl.value = getAssetById(props.item.cover)
        imgUrl.value.url = addCloudinaryUrlParams(imgUrl.value.url, 'w_75')
      }
    })

    return {
      goToNextActivity,
      imgUrl,
    }
  },
}
</script>