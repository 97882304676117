<template>
  <div class="c-curtain" :class="[{ 'c-curtain--hidden': !isCurtainOpen }]">
    <div class="c-curtain__container">
      <div class="c-curtain__close" v-if="curtainOptions.allowDissmiss">
        <TheButton
          @click="closeCurtain"
          type="secondary-alt"
          circle
          icon="close"
        />
      </div>
      <component :is="curtainComponent" />
    </div>
    <div
      class="c-curtain__overlay"
      @click="curtainOptions.allowDissmiss ? closeCurtain() : null"
    />
  </div>
</template>

<script>
import useCurtain from '@/composables/useCurtain'

import CurtainFeedback from '@/components/CurtainFeedback'
import CurtainAction from '@/components/CurtainAction'
import CurtainLanguageSwitcher from '@/components/CurtainLanguageSwitcher'
import TheButton from '@/components/TheButton'

export default {
  components: {
    CurtainFeedback,
    CurtainAction,
    CurtainLanguageSwitcher,
    TheButton,
  },

  setup() {
    const {
      isCurtainOpen,
      curtainComponent,
      closeCurtain,
      curtainOptions,
    } = useCurtain()

    return {
      curtainComponent,
      isCurtainOpen,
      closeCurtain,
      curtainOptions,
    }
  },
}
</script>
