<template>
  <div v-if="data" class="c-curtain-action">
    <div class="c-curtain-action__content">
      <img class="c-curtain-action__img" :src="data.img" :alt="data.title" />
      <TheHeading class="c-curtain-action__title">
        {{ data.title }}
      </TheHeading>
      <div class="c-curtain-action__description">
        {{ data.description }}
      </div>
    </div>
    <div class="c-curtain-action__buttons">
      <TheButton
        class="c-curtain-action__cancel"
        :type="data.cancel.btnType"
        @click="data.cancel.action"
      >
        {{ data.cancel.title }}
      </TheButton>
      <TheButton
        class="c-curtain-action__submit"
        :type="data.submit.btnType"
        @click="data.submit.action"
      >
        {{ data.submit.title }}
      </TheButton>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import {
  getActivityProgress,
  createOrUpdateActivityProgress,
} from '../services/progress'

import useCurtain from '@/composables/useCurtain'
import useActivity from '@/composables/useActivity'
import useTenant from '@/core/useTenant'

import TheHeading from '@/components/TheHeading'
import TheButton from '@/components/TheButton'

export default {
  components: {
    TheHeading,
    TheButton,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()
    const { tenant } = useTenant()
    const { goToArena } = useActivity()
    const { curtainType, curtainData, closeCurtain } = useCurtain()
    const { t } = useI18n()
    const data = computed(() => availableActions[curtainType.value])
    const availableActions = {
      ExitOverlay: {
        img: `/themes/${tenant.value.theme}/assets/exit-overlay.png`,
        title: t('CURTAIN_ACTION_EO_TITLE'),
        description: t('CURTAIN_ACTION_EO_DESCRIPTION'),
        cancel: {
          title: t('CURTAIN_ACTION_EO_CANCEL'),
          action: () => {
            goToArena()
            closeCurtain()
          },
          btnType: 'transparent',
        },
        submit: {
          title: t('CURTAIN_ACTION_EO_SUBMIT'),
          action: closeCurtain,
          btnType: 'primary',
        },
      },
      ResetActivity: {
        img: `/themes/${tenant.value.theme}/assets/reset-activity.png`,
        title: t('CURTAIN_ACTION_RACTIVITY_TITLE', {
          title: curtainData.value?.name,
        }),
        description: t('CURTAIN_ACTION_RACTIVITY_DESCRIPTION'),
        cancel: {
          title: t('CURTAIN_ACTION_CANCEL'),
          action: closeCurtain,
          btnType: 'danger',
        },
        submit: {
          title: t('CURTAIN_ACTION_RACTIVITY_SUBMIT'),
          action: async () => {
            const ap = await getActivityProgress(curtainData.value?.id)
            ap.itemsCompleted = []
            ap.reset = true
            ap.itemStates = null
            await createOrUpdateActivityProgress(curtainData.value?.id, ap)
            router.push(
              `/activity/${route.params.arena}/${curtainData.value?.slug}`,
            )
            closeCurtain()
          },
          btnType: 'primary',
        },
      },
    }

    return {
      data,
    }
  },
}
</script>
