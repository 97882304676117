<template>
  <div class="c-curtain-lang-switch">
    <div class="c-curtain-lang-switch__content">
      <div class="c-curtain-lang-switch__img-wrapper">
        <img class="c-curtain-lang-switch__img" :src="imagePath" alt="" />
      </div>
      <div class="c-curtain-lang-switch__text">
        <TheHeading class="c-curtain-lang-switch__title">
          {{ $t(langConstansts[curtainType].title) }}
        </TheHeading>
        <p
          class="c-curtain-lang-switch__description"
          v-md-html="$t(langConstansts[curtainType].desc)"
        ></p>
      </div>
    </div>
    <div class="c-curtain-lang-switch__buttons">
      <div class="c-curtain-lang-switch__buttons-select">
        <TheButton
          v-for="lang in langs"
          :key="lang.code"
          class="c-curtain-lang-switch__button"
          :class="[
            {
              'c-curtain-lang-switch__button-active': currentLang === lang.code,
            },
          ]"
          type="secondary-alt"
          @click="setLang(lang.code)"
          >{{ lang.label }}</TheButton
        >
      </div>
      <TheButton
        class="c-curtain-lang-switch__save"
        type="primary"
        @click="onSave"
        >{{ $t(langConstansts[curtainType].save) }}</TheButton
      >
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from 'vue'

import useCurtain from '@/composables/useCurtain'
import useTenant from '@/core/useTenant'
import TheButton from '@/components/TheButton'
import TheHeading from '@/components/TheHeading'
import { useI18n } from 'vue-i18n'

import {
  settings,
  getSetting,
  createOrUpdateSetting,
} from '../services/userSettings'

export default {
  components: {
    TheHeading,
    TheButton,
  },

  setup() {
    const { tenant } = useTenant()
    const { closeCurtain, curtainType } = useCurtain()
    const langs = computed(() => tenant.value.langs)
    const currentLang = ref(null)
    const { locale } = useI18n()

    const langConstansts = {
      langSwitcherAutoInvoked: {
        title: 'CURTAIN_LANG_SELECT_TITLE',
        desc: 'CURTAIN_LANG_SELECT_DESCRIPTION',
        save: 'CURTAIN_LANG_SELECT_SAVE',
      },
      langSwitcherUserInvoked: {
        title: 'CURTAIN_LANG_SWITCH_TITLE',
        desc: 'CURTAIN_LANG_SWITCH_DESCRIPTION',
        save: 'CURTAIN_LANG_SWITCH_SAVE',
      },
    }

    const imagePath = computed(
      () => `/themes/${tenant.value.theme}/assets/lang-switch.png`,
    )
    const onSave = () => {
      setLang(currentLang.value)
      location.reload()
      closeCurtain()
    }

    onBeforeMount(async () => {
      currentLang.value =
        (await getSetting(settings.lang))?.value || locale.value
    })

    const setLang = async lang => {
      currentLang.value = lang
      locale.value = lang
      await createOrUpdateSetting(settings.lang, lang)
    }

    return {
      langs,
      currentLang,
      onSave,
      setLang,
      imagePath,
      curtainType,
      langConstansts,
    }
  },
}
</script>
