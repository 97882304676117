<template>
  <div class="c-curtain-feedback">
    <div class="c-curtain-feedback__content">
      <div class="c-curtain-feedback__img-wrapper">
        <img class="c-curtain-feedback__img" :src="imagePath" alt="" />
      </div>
      <div class="c-curtain-feedback__text">
        <TheHeading class="c-curtain-feedback__title">
          {{ $t('CURTAIN_FEEDBACK_TITLE') }}
        </TheHeading>
        <p class="c-curtain-feedback__description">
          {{ $t('CURTAIN_FEEDBACK_DESCRIPTION') }}
        </p>
      </div>
    </div>
    <div v-if="nextItem" class="c-curtain-feedback__box">
      <TheHeading level="h3" class="c-curtain-feedback__box-title">
        {{ $t('CURTAIN_FEEDBACK_BOX_TITLE') }}
      </TheHeading>
      <div class="c-curtain-feedback__box-items">
        <CurtainFeedbackBoxItem :item="nextItem" />
      </div>
    </div>
    <div class="c-curtain-feedback__buttons">
      <TheButton
        class="c-curtain-feedback__back"
        type="transparent"
        @click="onBack"
      >
        {{ $t('CURTAIN_FEEDBACK_BACK') }}
      </TheButton>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStructures } from '@/core'
import useCurtain from '@/composables/useCurtain'
import useActivity from '@/composables/useActivity'
import useTenant from '@/core/useTenant'

import TheHeading from '@/components/TheHeading'
import TheButton from '@/components/TheButton'
import CurtainFeedbackBoxItem from '@/components/CurtainFeedbackBoxItem'
import { getActivityProgress } from '@/services/progress'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    CurtainFeedbackBoxItem,
    TheHeading,
    TheButton,
  },

  setup() {
    const { tenant } = useTenant()
    const route = useRoute()
    const { getStructuresBySlugPath } = useStructures()
    const { goToArena } = useActivity()
    const { closeCurtain } = useCurtain()
    const arenaSlugPath = computed(
      () => `${tenant.value.slug}/arenas/${route.params.arena}`,
    )
    const imagePath = computed(
      () => `/themes/${tenant.value.theme}/assets/feedback.png`,
    )
    const onBack = () => {
      goToArena()
      closeCurtain()
    }
    const nextItem = ref(null)
    const { locale } = useI18n()

    onMounted(async () => {
      const arena = getStructuresBySlugPath(
        arenaSlugPath.value,
        true,
        'DIRECTORY',
        locale.value,
      )
      for (const item of arena.childrens) {
        const ap = await getActivityProgress(item.id)
        if (!ap.done) {
          nextItem.value = item
          break
        }
      }
    })

    return {
      onBack,
      closeCurtain,
      imagePath,
      nextItem,
    }
  },
}
</script>
